.container-cta.dark-cta .fa-icon {
  font-size: 60px;
  margin: 0 10px;
}

.share-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.share-buttons > div {
  margin: 5px;
}
