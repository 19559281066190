.hexmap {
  height: 600px;
  width: 100%;
  margin-top: 1em;
  position: relative;
  animation-duration: 0.3s;
}

.hexmap .hex-cell {
  stroke: black;
  stroke-width: 0;
  transition: fill 0.2s ease-in, stroke 0.2s ease-in, stroke-width 0.2s ease-in;
}

.hexmap .hover path {
  stroke-width: 2px;
}

@media only screen and (max-width: 700px) {
  .hexmap .hex-label {
    display: none;
  }
}

.hexmap .tooltip {
  position: absolute;
  text-align: left;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 0.25em 0.5em;
  transform: translate3d(-50%, 50%, 0);
  transition: left 0.1s linear, top 0.1s linear;
  border-radius: 4px;
  z-index: 1000;
  min-width: 200px;
  --arrow-left: 50%;
}

.hexmap .tooltip.tooltip-winner {
  margin: 0;
  transform: translate3d(-50%, 18%, 0);
}

.hexmap .tooltip::after {
  content: "";
  position: absolute;
  bottom: auto;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  left: var(--arrow-left);
  top: 0%;
  transform: translate3d(-50%, -100%, 0);
  border-color: transparent;
  border-bottom-color: black;
}

.hexmap-legend {
  position: absolute;
  top: 20px;
  right: 100px;
  display: flex;
  align-items: stretch;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000; /* Ensure the legend appears above the hexmap */
}

.legend-gradient {
  width: 30px;
  display: flex;
  flex-direction: column-reverse;
}

.gradient-stop {
  flex-grow: 1;
}

.legend-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0px;
  font-size: 14px;
}

.max-label,
.min-label {
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .hexmap-legend {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .hexmap-legend {
    display: none;
  }
  .hexmap {
    height: 420px;
  }
}
