.beeswarm-tooltip {
  position: absolute;
  text-align: left;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.25em 0.5em;
  border-radius: 4px;
  pointer-events: none;
  z-index: 1000;
  min-width: 200px;
  font-size: 12px;
  --arrow-left: 50%;
}

.beeswarm-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
  left: var(--arrow-left);
  bottom: 0;
  transform: translate(-50%, 100%);
}

.beeswarm-tooltip b {
  font-size: 14px;
}

.beeswarm-tooltip div {
  margin-top: 5px;
}
